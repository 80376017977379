<template lang="pug">
include ../../../../configs/template
div.row.text-left
  div(v-if="sailorDocument.extent_original").col-sm-12.col-md-6
    +data-info('educationExtent', 'sailorDocument.extent_original')
  div(v-if="sailorDocument.name_nz_original").col-sm-12
    +data-info('nameInstitution', 'sailorDocument.name_nz_original')
  div(v-if="sailorDocument.qualification_original").col-sm-12
    +data-info('qualification', 'sailorDocument.qualification_original')
  div(v-if="sailorDocument.speciality_original").col-sm-12
    +data-info('specialty', 'sailorDocument.speciality_original')
  div(v-if="sailorDocument.speciality_original").col-sm-12
    +data-info('profession', 'sailorDocument.speciality_original')
  div(v-if="sailorDocument.specialization_original").col-sm-12
    +data-info('specialization', 'sailorDocument.specialization_original')
  div(v-if="sailorDocument.special_notes_original").col-sm-12
    +data-info('notes', 'sailorDocument.special_notes_original')
</template>

<script>
import { getDateFormat, getStatus } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      getDateFormat,
      getStatus,
      checkAccess,
      rejectStatusId: 13,
      isOriginal: false,
      sailorOriginalDocument: undefined
    }
  },
  computed: {
    ...mapGetters(['educationTypeDocumentByID', 'extentByID', 'institutionByID', 'educationProfessionByID',
      'specializationByID', 'qualificationLevelByID', 'statusById', 'rejectionReasonByID']),
    ...mapState({
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    verificationRefusalReason () {
      return this.comments.find(item => item.additional_info.cancel_reason)
    },
    verificationRefusalReasonName () {
      return this.rejectionReasonByID(this.verificationRefusalReason.additional_info.reason)[this.labelName]
    }
  },
  methods: {
    ...mapActions(['getDocumentNostrificationById']),
    async getOriginalDocument () {
      if (!this.sailorOriginalDocument) {
        this.sailorOriginalDocument = await this.getDocumentNostrificationById({ documentId: this.sailorDocument.nostrification_document })
      }
    }
  }
}
</script>
